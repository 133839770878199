<template>
  <div class="main">
    <van-field label="企业名称：" label-width="120" v-model="query.info.comName" disabled />
    <van-field label="统一征信代码：" label-width="120" v-model="query.info.uscc" disabled />
    <van-field label="工商注册地址：" label-width="120" v-model="query.more.regAddress" disabled />
    <van-field label="单位类型：" label-width="120" v-model="query.more.comSort" disabled />
    <van-field label="所属环保局：" label-width="120" v-model="query.info.inEpa" disabled />
    <van-field label="企业负责人姓名：" label-width="120" v-model="query.info.legalName" disabled />
    <van-field label="企业负责人电话：" label-width="120" v-model="query.info.legalPhone" disabled />
    <van-field label="环保负责人姓名：" label-width="120" v-model="query.info.managerName" disabled />
    <van-field label="环保负责人电话：" label-width="120" v-model="query.info.managerPhone" disabled />
    <van-field label="专职环保人员数：" label-width="120" v-model="query.more.envStaffNums" disabled />
    <van-field label="所属行政区域：" label-width="120" v-model="query.more.divisionAreaId" disabled />
    <van-field label="年末职工总数：" label-width="120" v-model="query.more.yearStaffNums" disabled />
    <van-field label="年总产值(万元)：" label-width="120" v-model="query.more.yearOutputValue" disabled />
    <van-field label="产品销售额(万元)：" label-width="125" v-model="query.more.saleMoney" disabled />
    <van-field label="总投资(万元)：" label-width="120" v-model="query.more.totalInvest" disabled />
    <van-field label="占地面积(㎡)：" label-width="120" v-model="query.more.floorSpace" disabled />
    <van-field label="实施地址：" label-width="120" v-model="query.more.workAddress" disabled />
    <van-field label="所属行业：" label-width="120" v-model="query.more.businessCategory" disabled />
    <van-field label="固废产废规模：" label-width="120" v-model="query.more.wasteScale" disabled />
    <van-field  v-model="remark" rows="2" autosize disabled label="规模备注" type="textarea" maxlength="50" label-width="120" show-word-limit />
    <van-field label="发票接收邮箱：" label-width="120" v-model="query.more.invoiceReceiveEmail" disabled />
    <van-field label="固定电话：" label-width="120" v-model="query.more.linkPhone" disabled />
    <van-field label="开户行：" label-width="120" v-model="query.more.openBank" disabled />
    <van-field label="账号：" label-width="120" v-model="query.more.bankAccount" disabled />
    <van-field label="固废类型：" label-width="120" v-model="query.info.categoryScope" disabled />
    <van-field label="处置费(焚烧)：" label-width="120" v-model="query.info.disposeFeeIncinerate" disabled />
    <van-field label="按重量运算(焚烧)：" label-width="130" v-model="query.info.weightFreightIncinerate" disabled />
    <van-field label="按体积运算(焚烧)：" label-width="130" v-model="query.info.volumeFreightIncinerate" disabled />
    <van-field label="处置费(填埋)：" label-width="120" v-model="query.info.disposeFeeLandfill" disabled />
    <van-field label="按重量运算(填埋)：" label-width="130" v-model="query.info.weightFreightLandfill" disabled />
    <van-field label="是否经过岗位培训：" label-width="130" v-model="query.more.instructorIsPro" disabled />
    <van-field label="固废指导员：" label-width="120" v-model="query.more.instructor" disabled />
    <van-field label="是否委托一般工业固废台账的建立和管理：" label-width="270" v-model="query.more.isTrustBook" disabled />
    <van-field label="是否代办毒性检测出勾选：" label-width="170" v-model="query.more.isDoViral" disabled />
    <van-field label="是否收到检测费：" label-width="130" v-if="query.more.isDoViral == 1 || query.more.isDoViral == '是'" v-model="query.more.isDoViralFee" disabled />
    <van-field label="出具检测报告时间：" label-width="140" v-if="query.more.isDoViral == 1 || query.more.isDoViral == '是'" v-model="query.more.doViralTime" disabled />
    <van-field label="是否提供环评报告(备案)：" label-width="170" v-model="query.more.isOfferEnv" disabled />
    <van-field label="是否签订合同：" label-width="130" v-model="query.more.isSigningContract" disabled />
    <van-field label="是否收到服务费：" label-width="130" v-if="query.more.isSigningContract == 1 || query.more.isSigningContract == '是'" v-model="query.more.isReceiveFee" disabled />
    <van-field label="合同签订时间：" label-width="120" v-if="query.more.isSigningContract == 1 || query.more.isSigningContract == '是'" v-model="query.more.signingTime" disabled />
    <van-field label="充值币总额" label-width="120" v-model="query.info.balanceTotal" disabled />
    <van-field name="营业执照" label="营业执照：" label-width="80" disabled>
      <template #input>
        <van-uploader
          :deletable="false" 
          :show-upload="false"
          v-model="query.more.businessLicense" />
      </template>
    </van-field>
    <div class="beizhu">
      <p>说明：</p>
      <p>1. 企业信息审核后，无法自主修改，如需修改，请与平台管理员联系</p>
    </div>
    <div class="btn">
      <van-button round block type="info" @click="gobackClick">返回</van-button>
    </div>
  </div>
</template>

<script>
import { companyid } from '@/api/enterprise.js'
import { dictType } from '@/api/notice.js'
import { querymoreInfo, divisioncode } from '@/api/order.js'
import { conversionTime } from '@/api/conversionTime.js'
import { Toast } from 'vant'
export default {
  data () {
    return {
      client: new OSS.Wrapper({
        region: 'oss-cn-hangzhou',
        accessKeyId: 'LTAI5tRypK9PNyfDKPcabDhD',
        accessKeySecret: 'ykKGkcSM0ewsUcW4rAUd9htNOJwKVp',
        bucket: 'hzjiaduomei-huishou'
      }),
      query: {
        info: {
          comName: undefined, // 企业名称
          uscc: undefined, // 统一征信代码
          balanceTotal: undefined, // 充值币总额
          inEpa: undefined, // 所属环保局
          managerName: undefined, // 环保负责人
          managerPhone: undefined, // 环保负责人电话
          legalName: undefined, // 企业负责人
          legalPhone: undefined, // 企业负责人电话
          disposeFeeIncinerate: undefined, // 处置费
          weightFreightIncinerate: undefined, // 按重量运算
          volumeFreightIncinerate: undefined, // 按体积运算
          disposeFeeLandfill: undefined, // 处置费
          weightFreightLandfill: undefined, // 按重量运算
          categoryScope: undefined, // 固废类型
        },
        more: {
          divisionAreaId: undefined, // 所属行政区域
          comSort: undefined, // 单位类型 1集体
          envStaffNums: undefined, // 专职环保人员数
          floorSpace: undefined, // 占地面积（万平方米）
          workAddress: undefined, // 实施地址
          businessCategory: undefined, // 所属行业
          wasteScale: undefined, // 危废产废规模
          businessLicense: undefined, // 营业执照 图片路径
          invoiceReceiveEmail: undefined, // 发票接收邮箱
          openBank: undefined, // 开户行
          bankAccount: undefined, // 账号
          isTrustBook: undefined, // 是否委托一般工业固废台账的建立和管理 0否 1是
          isDoViral: undefined, // 是否代办一般工业固废（含污泥）的毒性检测报告 0否 1是
          isOfferEnv: undefined, // 是否提供环评报告（备案） 0否 1是
          isSigningContract: undefined, // 是否签订合同
          isReceiveFee: undefined, // 是否收到服务费 0否 1是
          isDoViral: undefined, // 是否代办一般工业固废（含污泥）的毒性检测报告 0否 1是
          isDoViralFee: undefined, // 是否收到检测费
          doViralTime: undefined, // 出具检测报告时间
          signingTime: undefined, // 合同签订时间
        }
      },
      remark: undefined,
      columns1: [], // 单位类型
      columns2: [], // 所属行业
      columns3: [], // 保存固废
      show: false, // 合同签订时间选择器弹层
      show2: false, // 检测报告选择器弹层
      currentDate: undefined, // 时间选择器当前时间
      minDate: new Date(2000, 0, 1) // 最小选择时间
    }
  },
  created() {
    this.dictType()
  },
  methods: {
    dictType() { // 字典
      dictType('com_sort').then(res => { // 单位类型
        if (res.data.code === 200) {
          this.columns1 = res.data.data
          dictType('business_category').then(res => { // 所属行业
            if (res.data.code === 200) {
              this.columns2 = res.data.data
              dictType('solid_scale').then(res => { // 危废产废规模
                if (res.data.code === 200) {
                  this.columns3 = res.data.data
                  if (this.$route.query.admin) {
                    this.companyid()
                  } else {
                    this.querymoreInfo()
                  }
                }
              })
            }
          })
        }
      })
    },
    divisioncode() { // 翻译行政区传code
      if (this.query.more.divisionAreaId) {
        divisioncode(this.query.more.divisionAreaId).then(res => {
          if (res.data.code === 200) {
            this.query.more.divisionAreaId = res.data.data.token
          }
        })
      }
    },
    querymoreInfo() { // 获取企业基本资料查看
      querymoreInfo().then(res => {
        if (res.data.code === 200) {
          this.query = res.data.data
          this.query.more.businessLicense = res.data.data.more.businessLicense ? [{url: this.client.signatureUrl(res.data.data.more.businessLicense)}] : []
          this.query.more.comSort = this.columns1.filter(item => item.dictValue == this.query.more.comSort)[0].dictLabel
          this.query.more.businessCategory = this.columns2.filter(item => item.dictValue == this.query.more.businessCategory)[0].dictLabel
          this.columns3.filter(item => {
            if (item.dictValue == this.query.more.wasteScale) {
              this.query.more.wasteScale = item.dictLabel
              this.remark = item.remark
            }
          })
          if (this.query.more.isTrustBook === 1) {
            this.query.more.isTrustBook = '是'
          } else {
            this.query.more.isTrustBook = '否'
          }
          if (this.query.more.isDoViral === 1) {
            this.query.more.isDoViral = '是'
          } else {
            this.query.more.isDoViral = '否'
          }
          if (this.query.more.isOfferEnv === 1) {
            this.query.more.isOfferEnv = '是'
          } else {
            this.query.more.isOfferEnv = '否'
          }
          if (this.query.more.instructorIsPro === 1) {
            this.query.more.instructorIsPro = '是'
          } else {
            this.query.more.instructorIsPro = '否'
          }
          if (this.query.more.isSigningContract === 1) {
            this.query.more.isSigningContract = '是'
          } else {
            this.query.more.isSigningContract = '否'
          }
          if (this.query.more.isReceiveFee === 1) {
            this.query.more.isReceiveFee = '是'
          } else {
            this.query.more.isReceiveFee = '否'
          }
          if (this.query.more.isDoViralFee === 1) {
            this.query.more.isDoViralFee = '是'
          } else {
            this.query.more.isDoViralFee = '否'
          }
          if (this.query.more.doViralTime) {
            this.query.more.doViralTime = this.conversionTime(this.query.more.doViralTime).split(' ')[0]
          }
          if (this.query.more.signingTime) {
            this.query.more.signingTime = this.conversionTime(this.query.more.signingTime).split(' ')[0]
          }
          this.divisioncode()
        }
      })
    },
    companyid() { // 获取管理员基本资料查看
      companyid(this.$route.query.detailsId).then(res => {
        if (res.data.code === 200) {
          this.query = res.data.data
          this.query.more.businessLicense = res.data.data.more.businessLicense ? [{url: this.client.signatureUrl(res.data.data.more.businessLicense)}] : []
          if (this.query.more.comSort) {
            this.query.more.comSort = this.columns1.filter(item => item.dictValue == this.query.more.comSort)[0].dictLabel
          }
          if (this.query.more.businessCategory) {
            this.query.more.businessCategory = this.columns2.filter(item => item.dictValue == this.query.more.businessCategory)[0].dictLabel
          }
          this.columns3.filter(item => {
            if (item.dictValue == this.query.more.wasteScale) {
              this.query.more.wasteScale = item.dictLabel
              this.remark = item.remark
            }
          })
          if (this.query.more.isTrustBook === 1) {
            this.query.more.isTrustBook = '是'
          } else {
            this.query.more.isTrustBook = '否'
          }
          if (this.query.more.isDoViral === 1) {
            this.query.more.isDoViral = '是'
          } else {
            this.query.more.isDoViral = '否'
          }
          if (this.query.more.isOfferEnv === 1) {
            this.query.more.isOfferEnv = '是'
          } else {
            this.query.more.isOfferEnv = '否'
          }
          if (this.query.more.instructorIsPro === 1) {
            this.query.more.instructorIsPro = '是'
          } else {
            this.query.more.instructorIsPro = '否'
          }
          if (this.query.more.isSigningContract === 1) {
            this.query.more.isSigningContract = '是'
          } else {
            this.query.more.isSigningContract = '否'
          }
          if (this.query.more.isReceiveFee === 1) {
            this.query.more.isReceiveFee = '是'
          } else {
            this.query.more.isReceiveFee = '否'
          }
          if (this.query.more.isDoViralFee === 1) {
            this.query.more.isDoViralFee = '是'
          } else {
            this.query.more.isDoViralFee = '否'
          }
          if (this.query.more.doViralTime) {
            this.conversionTime(this.query.more.doViralTime).split(' ')[0]
          }
          if (this.query.more.signingTime) {
            this.conversionTime(this.query.more.signingTime).split(' ')[0]
          }
          this.divisioncode()
        }
      })
    },
    gobackClick() {
      this.$router.go(-1)
    },
  }
}

</script>
<style lang="scss" scoped>
.main {
  padding-bottom: 10px;
  .beizhu {
    padding: 15px;
    font-size: 14px;
    p {
      line-height: 25px;
    }
  }
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
::v-deep .van-field__control:disabled {
  color: #333;
  -webkit-text-fill-color: #333;
}
</style>
